import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';

import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import KafkaIcon from '../../assets/svg/kafka-plain.svg';
import PostgresIcon from '../../assets/svg/postgresql-plain.svg';
import MongoIcon from '../../assets/svg/mongodb-plain.svg';
import NestIcon from '../../assets/svg/nestjs-plain.svg';
import NextIcon from '../../assets/svg/nextjs-plain.svg';
import SwiftIcon from '../../assets/svg/swift-original.svg';
import RedisIcon from '../../assets/svg/redis-plain.svg';
import GolangIcon from '../../assets/svg/golang.svg';
import NowoczesneGraphics from '../../assets/svg/nowoczesne-technologie.svg';
import SubLayout from '../layouts/subLayout';
import SEO from '../components/seo';

const TechPage = () => {
  const data = useStaticQuery(graphql`
        query TechImages {
            nowoczesnetechnologie: file(
                relativePath: { eq: "nowoczesne-technologie.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          <span>Technologie</span>
          , których używamy
        </>
              )}
      subtitle="Oszczędność dzięki nowoczesnym rozwiązaniom"
      img="/img/biuro-komputery-programowanie.jpg"
    >
      <SEO title="Stack Technologiczny - iqcode Software House" description="Zestaw technologii wykorzystywanych przez iqcode do tworzenia aplikacji mobilnych, stron i serwisów internetowych oraz aplikacji serwerowych." />
      <FeatureSectionLight
        title={(
          <>
            Nasz
            {' '}
            <span>Stack </span>
            Technologiczny
          </>
                      )}
        img={(
          <div style={{ padding: '3%' }}>
            <NowoczesneGraphics style={{ width: '100%', height: 'auto' }} />
          </div>
                      )}
        desc={(
          <>
            <p>
              Dobór odpowiedniego zestawu technologii jest
              niezwykle istotny z punktu widzenia wydajności,
              stabilności i łatwości późniejszej rozbudowy
              projektu programistycznego.
            </p>
            <p>
              W iqcode korzystamy z obszernego zbioru
              nowoczesnych technologii i narzędzi
              programistycznych, dzięki czemu możemy od
              początku realizować projekty bez obciążania ich
              zbędnym
              {' '}
              <strong>długiem technologicznym</strong>
              . W rezultacie, tworzone przez nas produkty cyfrowe
              oferują znakomitą wydajność, bezpieczeństwo i otwartość na modyfikacje,
              co czyni je łatwymi w rozbudowie i utrzymaniu.
            </p>
            <p>
              Naszą specjalnością jest tworzenie nowoczesnych aplikacji progresywnych (PWA)
              w React.js, szybkich i interaktywnych stron internetowych w Gatsby.js oraz
              skalowalnych aplikacji serwerowych w Node.js z wykorzystaniem architektury
              mikrousług (
              <i>microservices</i>
              ).
            </p>
          </>
                      )}
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Porozmawiajmy o Twoim
            {' '}
            <span>projekcie</span>
            !
          </h3>
          <Link className="btn" to="/kontakt/">
            Kontakt
          </Link>
        </div>
      </div>
      <FeatureCardsSection
        id="backend"
        title={(
          <>
            Technologie
            {' '}
            <span>Backendowe</span>
          </>
                  )}
        subtitle="Napędzające aplikacje działające po stronie serwera"
        cards={[
          {
            title: 'Node.js',
            desc:
                            'Środowisko uruchomieniowe języka JavaScript umożliwiające tworzenie wydajnych aplikacji serwerowych.',
            icon: <NodeIcon />,
          },
          {
            title: 'NestJS',
            desc:
                            'Progresywny framework Node.js służący do tworzenia aplikacji serwerowych.',
            icon: <NestIcon />,
          },
          {
            title: 'Python',
            desc:
                            'Najpopularniejszy na świecie język programowania, który wykorzystujemy do budowy aplikacji serwerowych, analizy danych i uczenia maszynowego.',
            icon: <PythonIcon />,
          },
          {
            title: 'Golang',
            desc:
                            'Nowoczesny język programowania, w którym tworzymy wydajne aplikacje serwerowe oraz rozszerzenia do technologii DevOps.',
            icon: <GolangIcon />,
          },
          {
            title: 'Redis',
            desc:
                            'Baza danych działająca w pamięci operacyjnej najczęściej wykorzystywana jako pamięć podręczna aplikacji serwerowych.',
            icon: <RedisIcon />,
          },
          {
            title: 'PostgreSQL',
            desc:
                            'Otwartoźródłowa relacyjna baza danych zapewniająca skalowalność i wysoką dostępność przez mechanizm replikacji.',
            icon: <PostgresIcon />,
          },
          {
            title: 'MongoDB',
            desc:
                            'Nowoczesna baza danych nurtu NoSQL umożliwiająca przechowywanie dużych rozmiarów danych w modelu dokumentowym.',
            icon: <MongoIcon />,
          },
          {
            title: 'Apache Kafka',
            desc:
                            'Rozproszona i skalowalna platforma przetwarzania strumieniowego wykorzystywana do komunikacji w architekturze mikrousług.',
            icon: <KafkaIcon />,
          },
        ]}
      />
      <FeatureCardsSection
        title={(
          <>
            Technologie
            {' '}
            <span>Frontendowe</span>
          </>
                  )}
        subtitle="Dla stron internetowych, aplikacji SPA oraz aplikacji progresywnych (PWA)"
        cards={[
          {
            title: 'React.js',
            desc:
                            'Nowoczesna biblioteka służąca do tworzenia przeglądarkowych interfejsów użytkownika w oparciu o model komponentowy.',
            icon: <ReactIcon />,
          },
          {
            title: 'Gatsby.js',
            desc:
                            'Ekosystem umożliwiający tworzenie wydajnych i bezpiecznych statycznych serwisów internetowych z wykorzystaniem React.js',
            icon: <GatsbyIcon />,
          },
          {
            title: 'Next.js',
            desc:
                            'Framework React.js umożliwiający statyczną generację stron oraz hybrydowe renderowanie po stronie serwera i klienta.',
            icon: <NextIcon />,
          },
        ]}
      />
      <FeatureCardsSection
        title={(
          <>
            Technologie Aplikacji
            {' '}
            <span>Mobilnych</span>
          </>
                  )}
        subtitle="Do tworzenia aplikacji na platformy Android i iOS"
        cards={[
          {
            title: 'React Native',
            desc:
                            'Uznany framework hybrydowy umożliwiający równoczesne tworzenie aplikacji na systemy Android i iOS.',
            icon: <ReactIcon />,
          },
          {
            title: 'Flutter',
            desc:
                            'Nowoczesna alternatywa dla React Native oferująca znakomitą wydajność aplikacji mobilnych.',
            icon: <FlutterIcon />,
          },
          {
            title: 'Swift',
            desc:
                            'Język programowania służący do budowy aplikacji natywnych dla urządzeń z systemami iOS i iPadOS.',
            icon: <SwiftIcon />,
          },
        ]}
      />
    </SubLayout>
  );
};

export default TechPage;
